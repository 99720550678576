.home {
    background-image: url('../../media/images/home1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 120vh;

    .bg-home {
        height: 100%;
        position: relative;
        width: 100%;

        .content-div {
            position: absolute;
            left: 15%;
            top: 15%;
            width: 50%;
            transition: .5s ease;
        }
    }

    button {
        width: 160px;
        padding: 1.5rem 0;
    }

    .work-btn {
        border: 2px solid white;
        background-color: white;
        color: #1370B0;
        transition: .2s ease;
    }

    .portfolio-btn {
        border: 2px solid white;
        background-color: transparent;
        color: white;
        transition: .2s ease;
    }

    .work-btn:hover {
        border: 2px solid white;
        background-color: transparent !important;
        color: white;
        scale: 1.05;
    }

    .portfolio-btn:hover {
        border: 2px solid white;
        background-color: white;
        color: #1370B0;
        scale: 1.05;
    }
}

.statestic-div {
    background-image: url('../../media/images/home2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 120vh;
    position: relative;

    .inner-container {
        gap: 70px;
        width: 100%;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        justify-content: space-between;

        .demo {
            height: 350px;
            width: 250px;
            position: relative;
            z-index: 99;
            background-image: url('../../media/images/statestic2.png');
            background-size: contain !important;
            background-position: center;
            background-repeat: no-repeat;
            .outer-container1 {
                width: 50%;
                margin: 0 auto;
                padding-top: 60px;

                .description {
                    font-size: 13px;
                }
                .title{
                    font-size: 25px;
                }
                .s-title {
                    font-weight: 400;
                    line-height: 25px;
                    font-size: 24px;
                }
            }
        }

    }
}

.technologies-div {
    background-image: url('../../media/images/home3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 105vh;
    position: relative;
    margin: 5vh 0;

    .inner-container {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);

        .gapping {
            gap: 3rem;
        }

        .imgs {
            background-color: black;
            padding: 10px;
            height: 80px;
            width: 80px;
            border-radius: 50%;
            border: 2px solid #1370B0;
            outline: 15px solid white;
        }
    }
}

.progress-div {
    img {
        width: 70%;
    }

    .bg {
        background-image: url(../../media/images/progress-bg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 80px;
        position: relative;
        transition: 0.5s ease;

        img {
            width: 40px;
            padding-top: 15px;
            height: 70%;
        }

        .grey {
            position: absolute;
            top: 40%;
            left: -15%;
        }

        .numbers {
            background-color: #1370B0;
            border-radius: 50%;
            padding: 0 7px;
            position: absolute;
            bottom: 0px;
            left: 20%;
        }
    }

    .bg:hover {
        scale: 1.2;
    }
}

@media only screen and (max-width:520px) {
    .progress-div {
        .col:nth-child(4) {
            order: 6;
        }

        .col:nth-child(5) {
            order: 5;
        }

        .col:nth-child(6) {
            order: 4;
        }

        .grey {
            left: -18px !important;
        }

    }

    .chooseUs-div {
        .choose-title {
            padding-bottom: 0px !important;
            padding-top: 0.5rem !important;
        }
    }

    .long-arrow {
        background-image: url(../../media/images/arrow.png);
        background-repeat: no-repeat;
        position: absolute;
        right: 0px;
        top: 20%;
        // transform: translate(-50%);
        height: 100%;
        width: 15px;
    }

    .statestic-div {
        background-image: url('../../media/images/home2mobile.png') !important;
        height: 70vh !important;

        .demo {
            height: 250px !important;
            width: 100vw !important;
            background-size: contain !important;
            background-position: center;
            .outer-container1{
                width: 38% !important;
                padding-top: 40px !important;
                .description{
                    font-size: 11px !important;
                }
            }
        }
    }

    .technologies-div {
        background-image: url('../../media/images/home3mobile.png') !important;
        height: 70vh;

        img {
            width: 50px !important;
            height: 50px !important;
        }
    }

    .home {
        background-image: url('../../media/images/homemobile.png') !important;
        height: 100vh;
    }
}

@media only screen and (min-width:1025px) {
    .technologies-div {
        img {
            width: 10% !important;
            height: auto !important;
        }
    }

    .statestic-div {
        .inner-container {
            width: 70%;
        }
    }
}