.footter{
    background-image: url('../../media/images/home4.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    .row>*{
        padding-left: 0 !important;
    }
    .extra-p{
        padding-left: 2.5rem !important;
    }
    .main-description{
        line-height: 30px;
    }
    .description{
        font-size: 14px;
       .icons{
            background-color: #1370B0;
            color: white;
            padding: 5px 3px;
            width: 7%;
            margin-right: 10px;
            border-radius: 50%;
        }
        .m-0{
            cursor: pointer !important;
        }
        ul{
            padding: 0;
            margin: 0;
            list-style-type: none;
            li{
                list-style-type: none;
                cursor: pointer;
            }
        }
    }
}
.copyright-div{
    font-size: 18px;
    font-weight: 400;
    .icons{
            color: white;
            padding: 5px 7px;
            // font-size: 30px;
            width: 10%;
            margin-right: 10px;
            border-radius: 50%;
            box-shadow: 0 0 7px 1px  rgba(255, 255, 255, 0.322) !important; 
           }
}

@media only screen and (max-width:520px) {
    .footter{
        height: fit-content;
        padding-top: 50px;
    }
    .copyright{
        font-size: 14px;
    }
    .copyright-div{
        padding: 10px;
        .icons{
            margin: 0 1px;
        }
    }
    .icons-div{
        width: 80%;
    }
}