.contactUs-div{
    .heading{
        font-size: 20px;
        font-weight: 400;
    }
    .height{
        height: 120px;
    }
    .contact{
        border-radius: 10px;
        height: 100%;
        background-image: linear-gradient(to bottom, #B3D1E5, #478FC1);
        .icons{
            background-color: #1370B0;
            color: white;
            padding: 9px 12px;
            border-radius: 50%;
        }
        .content{
            font-size: 14px;
            .title{
                font-size: 20px;
                font-weight: 400;
                color: #1370B0;
            }
        }
    }
    .contact-form{
        padding: 30px 100px;
        .form-group{
            margin-bottom: 20px;
            p{
                margin: 0;
            }
        }
        .form-control{
            border: none;
            background-color: #1D212D;
            border-radius: 0;
            border-bottom: 1px solid white;
            color: white !important;
            font-size: 16px; 
        }
        .form-control::placeholder{
            color: white;
        }
        button{
            background-color: #1370B0;
            width: 175px;
        }
    }
}

@media only screen and (max-width:520px){
    .contact-form{
        padding: 30px 0 !important;
    }
}