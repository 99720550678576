.our-projects {
    .projects-div {
        .col {
            width: 50%;
            padding: 5px;
        }

        .bg-col {
            border-radius: 15px;
            background-image: linear-gradient(to bottom, #C4DBEB, #458EC1);

            .project {
                border-radius: 15px;
                background-color: rgba(0, 0, 0, 0.42);
                height: 300px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: relative;
                cursor: pointer;

                .project-title {
                    -webkit-text-stroke: 1px white;
                    /* Safari/Chrome */
                    text-stroke: 1px white;
                    /* Firefox */
                    color: transparent;
                    /* Set the text color to transparent */
                    font-size: 26px;
                    position: absolute;
                    display: block;
                    top: 5%;
                    width: 100%;
                }
                .project-btn-div{
                    position: absolute;
                    width: 100%;
                    bottom: 5%;
                    .project-btn{
                        padding: 5px 25px;
                        border: 0;
                        color: white;
                        background-color: #1371b0dc;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:520px) {
    .our-projects{
        .project{
            height: 240px !important;
            .project-title{
                font-size: 22px !important;
            }
        }
    }
}
@media only screen and (min-width:1600px) {
    .projects-div{
        width: 70% !important;
        margin: 0 auto;
    }
}