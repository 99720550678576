.blue{
    color: #1370B0;
}
.bg-bblue{
    background-color: #1370B0;
}

.black{
    color: black;
}

.grey{
    color: #969696;
}

.sky{
    color: #D3EAFA;
}

.main-heading{
    font-size: 56px;
    font-weight: 500;
    // display: inline-block;
    transition: 0.5s ease;
}

.main-description{
    font-size: 26px;
    font-weight: 500;
    transition: 0.5s ease;
}

.main-content{
    font-size: 20px;
    font-weight: 300;
    transition: 0.5s ease;

}
.slick-prev{
  left: 1px !important;
  z-index: 999 !important;
}
.slick-right ,.slick-next{
  right: 1px !important;
  z-index: 999 !important;
}
.slick-dots{
  li{
    button:before{
      font-size: 12px;
      color: white !important;
    }
  }
  .slick-active{
    button:before{
      color: #1370B0 !important;
    }
  }
}
/* Define keyframes for fade-in, fade-out, and scaling animations */
@keyframes fade-in {
    from {
      opacity: 0.5;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.9);
    }
  }
  
  /* Apply animations to the specific classes */
  
  .main-heading:hover {
    scale: 1.2;
  }  
  .main-content:hover{
    scale: 1.05;
  }
  .main-description:hover{
    scale: 1.1;
    // color: #54C5F8;
  }
.aboutUs-heading{
    font-size: 48px;
    font-weight: 500;
}

.aboutUs-content{
    font-size: 16px;
    font-weight: 300;
}

.spacing{
    padding: 30px 100px;
}
.btn-width{
    width: 175px;
    border: 0;
    font-weight: 400;
    padding: 7px 0;
}

@media only screen and (min-width:1025px) {
  .spacing{
    padding: 50px 200px;
}
}

@media only screen and (max-width:520px) {
    .main-heading{
    font-size: 24px;
}
.main-description{
    font-size: 16px;
}
.main-content{
    font-size: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
 .aboutUs-heading{
    font-size: 24px;
 }   
 .aboutUs-content{
    font-size: 10px;
 }   
.spacing{
    padding: 20px;
}
        .content-div{
            width: 100%;
            left: 0;
        }
}
@media only screen and (min-width:600px) and (max-width:800px) {
  .main-heading{
  font-size: 36px;
}
.main-description{
  font-size: 24px;
}
.main-content{
  font-size: 16px;
}
}