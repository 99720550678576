.chooseUs-div{
    .outer-container{
        padding: 7px;
        .container{
            position: relative;
            height: 250px;
            padding: 0;
            background-image:  linear-gradient(45deg,#A6C9E1,#4890C2);
            .description{
                font-size: 12px;
            }
            .image-container{
                position: absolute;
                height: 92px;
                width: 92px;
                border-radius: 50%;
                left: 50%;
                top:8%;
                z-index: 999;
                transform: translate(-50%);
                background-color:  #68A4CD;
                border: 4px solid white;
                outline: 5px solid #68A4CD;
                outline-offset: 0px;
                .inner-circle{
                    height: 78px;
                    width: 78px;
                    background-color: white;
                    border-radius: 50%;
                    border: 3px solid white;
                    outline: 4px solid #68A4CD;
                outline-offset: -1px;
                background-image: radial-gradient(circle at center, white 0, #CFCFCF 100%);
                img{
                    height: 70%;
                    width: 70%;
                }
                }
            }
            .inner-div{
                position: absolute;
                height: 65%;
                width: 100%;
                bottom: 0%;
                left: 0%;
                border-radius: 0 0 1rem 1rem ;
            }
        }
    }

    .slick-prev {
        left: -14px !important;
    }

    .slick-next {
        right: -14px !important;
    }
}

@media only screen and (min-width:1025px) {
    .outer-container{
        padding: 10px 20px !important;
    }
}
@media only screen and (min-width:1700px) {
    .outer-container{
        padding: 30px!important;
    }
    .chooseUs-div{
        padding: 50px 300px;
    }
}