.courses-div{
    .main-content{
        font-size: 14px;
    }
    .button{
        width: 150px;
        border: 2px solid white;
        background-color: transparent;
        border-radius: 20px;
        font-size: 12px;
        padding: 5px 0 ;
        color: white;
    }
    ul{
        text-align: left;
        list-style-type: '- ';
        width: 40%;
       margin-left: 30%;
        li{
            padding: 0;
            width: 40%;
            font-size: 12px;
            font-weight: 300;
        }
    }
    .bullets-sec{
        width: 60%;
        margin: 0 auto;
    }
    .bullets{
        font-size: 16px;
        text-align: left;
        margin-bottom: 1rem;

        p{
            margin: 0;
        }
    }
   
}

@media only screen and (max-width:768px) {
    .courses-div{
        ul{
            width: initial !important;
           margin-left: initial !important;
        }
        .bullets-sec{
            width: 100% !important;
            margin: 0 auto;
        }
    }
}