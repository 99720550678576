
    .form-spacing{
        padding: 30px 200px;
        padding-bottom: 80px;
        .gradient{
            background-image:  linear-gradient(45deg,#1371b03f,#1370B0);
            width: 100%;
            .inner-space{
                padding: 40px 60px 50px 200px;
                position: relative;
                .contact-div{
                    width: 30%;
                    height: 60%;
                    position: absolute;
                    padding: 15px;
                    top: 20%;
                    left: 10%;
                    background-color: #1370B0;
                    border-radius: 20px;
                    z-index: 999;
                    .description{
                        font-size: 14px;
                        p{
                            padding: 5px 0;
                        }
                       .icons{
                            background-color: #1370B0;
                            color: white;
                            padding: 5px 3px;
                            width: 7%;
                            margin-right: 10px;
                            border-radius: 50%;
                        }
                    }
                    .icon-div{
                        .icons{
                            color: white;
                            padding: 5px 3px;
                            // font-size: 30px;
                            width: 10%;
                            margin-right: 10px;
                            border-radius: 50%;
                            box-shadow: 0 0 5px 0px  rgba(255, 255, 255, 0.322) !important; 
                           }
                    }
                }
                .form-div{
                    width:100%;
                    background-color: white;
                    border-radius: 20px;
                    form{
                        padding: 1.5rem;
                        padding-left: 30%;
                        // float: right;
                        .form-group{
                            margin-bottom: 20px;
                        }
                        .form-control{
                            border: none;
                            border-radius: 0;
                            border-bottom: 1px solid black;
                            color: #1370B0;
                            font-size: 20px;
                        }
                        button{
                            background-color: #1370B0;
                            width: 175px;
                        }
                        .text-danger{
                            margin: 0;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
   
@media only screen and (max-width:520px) {
    .form-spacing{
        padding: 0;
        .inner-space{
            padding: 40px 20px !important;
            justify-content: space-between;
            .contact-div{
                position:  relative !important;
                width: 49% !important;
                height: 396px !important; 
                padding: 1rem 0.5rem!important;
                top: 0 !important ;
                left: 0 !important;
                .icon-div{
                    justify-content: space-evenly;
                }
            }
            .form-div{
                width:49% !important;
                form{
                    padding: 1rem !important;
                    padding-left: 1rem !important;
                    .form-control{
                        font-size: 11px !important;
                        margin-bottom: 5px !important;
                    }
                    button{
                        width: 80% !important;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 521px) and (max-width:769px) {
    .form-spacing{
        padding: 20px;
        .contact-div{
            width: 35% !important;
        }
    }
    }
    @media only screen and (max-width:1025px) {
            .contact-div{
                width: 35% !important;
            }
        }

    @media only screen and (min-width:1441px) {
        .form-spacing{
            padding: 30px 500px;
            .contact-div{
                left: 5% !important;
            }
        }
        }