@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800&display=swap");
@import 'bootstrap/dist/css/bootstrap.min.css';
@import '/node_modules/font-awesome/css/font-awesome.min.css';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';

body {
  margin: 0;
  color: white;
  background-color: #1D212D;
   font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

