.service-div{
    .service-cards{
        padding: 0 10px ;
        transition: .4s ease;
      .services-content{
        border-radius: 20px;
        background-color: white;
        background-image:  linear-gradient(to right,#1371b037,#1370B0),
        linear-gradient(to bottom,#1371b037,#1370B0);
        img{
            margin: 0 auto;
        }
        p{
            font-size: 26px;
            padding-top: 10px;
            margin-bottom: 0;
        }
      }
    }
    .slick-dots{
        button::before{
            color: white;
        }
        .slick-active{
            button::before{
                color: #1370B0;
        }
    }
}
.service-cards:hover{
    scale: 0.9;
}
}