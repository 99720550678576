.aboutUs-page,.service-page,.portfolio-page,.whyUs-page,.contactUs-page,.courseOne-page,.courseTwo-page,.courseThree-page{
    background-image: url('../../media/images/aboutUsPage.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    .bg{
        position: relative;
        height: 100%;
        width: 100%;
        .content-div{
            position: absolute;
            left: 50%;
            top: 40%;
            transform: translate(-50%,-50%);
        }
    } 
}
.service-page{
    background-image: url('../../media/images/servicePage.png');
}

.portfolio-page{
    background-image: url('../../media/images/portfoioPage.png');
}

.whyUs-page{
    background-image: url('../../media/images/whyUsPage.png');
}

.contactUs-page{
    background-image: url('../../media/images/contactUspage.png');
}

.courseOne-page{
    background-image: url('../../media/images/course1.png');
}

.courseTwo-page{
    background-image: url('../../media/images/course2.png');
}
.courseThree-page{
    background-image: url('../../media/images/course3.png');
}
