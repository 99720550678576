.navbar-main{
    .title{
        color:#54C5F8 ;
        width: 150px;
    }
    .active{
        color: #54C5F8;
    }
    img{
        transition: .2s ease;
    }
    img:hover{
        scale: 1.3;
    }
    .dropdown-div{
        .dropdown-toggle::after {
            display: none;
          }
        .btn.show{
            border: transparent !important;
        }
        .dropdown-menu{
            inset:5px auto auto 0px !important;
        }
        .dropdown-item.active {
            background-color:white !important;
        }
    }
    .horizontal-div{
        width: 50%;
        i{
            color: white;
        }
    }
    ul{
        list-style-type: none;
        margin: 0;
        height: 100%;
        li{
            margin: 0;
            text-align: center;
        }
    }
    a{
        text-decoration: none;
        color: white;
        transition: .2s ease;
    }
    a:hover{
        font-size: 20px;
        font-weight: 400;
        color: #54C5F8;
    }
    .nav-btn{
        background-color:#1370B0;
        border: 2px solid #1370B0;
        width: 220px;
        height: 50px;
        color: white;
        transition: .2s ease;
    }
    .nav-btn:hover{
        background-color:transparent;
        border: 2px solid #1370B0;
        width: 220px;
        height: 50px;
        color: #54C5F8;
    }
}

@media only screen and (max-width:520px) {
.nav-btn{
    width: max-content !important;
}
}