.course-div{
    .inner-div{
        background-color: #D2EDFF;
        border-radius: 0 0 20px 20px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .content{
            font-size: 12px;
        }
        .course-btn{
            padding: 8px 30px;
            background-color: #1370B0;
            border: 0;
            color: #D2EDFF;
            text-decoration: none;
            margin: 0 auto;
        }
        p{
            margin: 0 !important;
        }
    }
    .courses-content{
        transition: 0.5s ease;
    }
    .courses-content:hover{
       scale: 0.9;
    }
    .slick-prev{
        left: -20px !important;
    }
    .slick-next{
        right: -20px !important;
    }
}

@media only screen and (max-width:520px) {
    .course-div{
        .inner-div{
            height: 200px;
        }
    }
}